<template>
  <div>
    <v-subheader>
      <slot></slot>
    </v-subheader>
    <v-divider class="header"/>
  </div>
</template>

<script>
export default {
  name: 'DateListHeaderHeader'
}
</script>

<style scoped>
  .header {
    margin-bottom: 14px;
    margin-top: -10px
  }
</style>